const SERVER_LINK = "https://api.gomove.io";

export const REQUEST_TYPE = {
  GET: "GET",
  POST: "POST",
};

export const UPLOADS = {
  add : SERVER_LINK
};
